<template>
  <div>
    <c-table-attendance
      :title="title"
      :items="getItems"
      :columns="columns"
      :totalRows="totalRows"
      :perPage="perPage"
      :currentPage="currentPage"
      :api="api"
      :import="actions.import"
      :filterCourse="true"
      @changeCurrentPage="changeCurrentPage"
      @changePerPage="changePerPage"
    ></c-table-attendance>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import table from "@/libs/table";
import CTable from "@/components/CTable";
import CTableAttendance from '@/components/CTableAttendance';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    CTable,
    CTableAttendance,
    ToastificationContent,
  },
  data() {
    const permission = this.$store.getters["app/ACTIONS"];

    return {
      title: "Absensi",
      api: "student-grades",
      dir: false,
      items: [],
      totalRows: 0,
      perPage: 10,
      limit: 0,
      orderBy: "createdAt",
      sortBy: "desc",
      currentPage: 1,
      search: "",
      actions: {
        import: false,
        export: false,
      },
      columns: [
        { key: "no", label: "#", sortable: false },
        { key: "name", label: "nama blok", sortable: true },
        { key: "year", label: "tahun ajaran", sortable: true },
        { key: "jumlah_mahasiswa", label: "jumlah mahasiswa", sortable: true },
        {
          key: "action",
          label: "aksi",
          class: "text-center",
          actions: {
            show: true,
          },
        },
      ],
    };
  },
  mounted() {
    const _ = this;

    if (_.$route.params) {
      if (_.$route.params.variant) {
        _.toastNotification(
          _.$route.params.variant,
          "BellIcon",
          _.$route.params.text
        );
      }
    }
  },
  methods: {
    getItems(ctx, callback) {
      table.getItems(ctx, callback, this);
    },
    changeCurrentPage(page) {
      table.changeCurrentPage(this, page);
    },
    changePerPage(perPage) {
      table.changePerPage(this, perPage);
    },
    toastNotification(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Pemberitahuan",
          icon,
          text,
          variant,
        },
      });
    },
  },
};
</script>
